import { extractDateSerialAlertID } from 'common/alert'
import {
  AddAlertsToLabelingQueueParameters,
  Alert,
  AlertLabels,
  Alerts,
} from 'common/types'
import { ref } from 'firebase/database'
import { getDownloadURL, getStorage, ref as refStorage } from 'firebase/storage'
import { useCallback, useEffect, useState } from 'react'
import { markEntryAsLabeled, QueueEntry, useQueue } from 'shared/hooks/useQueue'
import { dataState, loadingState } from 'shared/types/asyncState'
import { DateString, FirebaseKey, Serial } from 'shared/types/utils'
import { fetch_ } from 'shared/utils/fetch'
import { onError } from 'shared/utils/web/error'
import { auth, database } from '../firebase'
import { remove, set, update } from '../firebaseMethods'

interface AlertFromURL {
  alert: Alert
  alertKey: FirebaseKey
}

type UseAlert = {
  alertKey: string
  serial: Serial
  date: DateString
  id: FirebaseKey
  saveLabels: (labels: string[]) => Promise<void>
} | null

async function getAlertFromURL() {
  const urlParameter = window.location.pathname.replace(/^\//, '')
  const dateSerialAlertID = extractDateSerialAlertID(urlParameter)

  if (dateSerialAlertID === undefined) return null

  const source = 'link'
  const why = 'unknown'
  const priority = 1
  const labelersTarget = 3

  const requestBody: AddAlertsToLabelingQueueParameters = {
    alerts: [
      `${dateSerialAlertID.date}/${dateSerialAlertID.date}/${dateSerialAlertID.alertID}`,
    ],
    source: source,
    why: why,
    priority: priority,
    labelersTarget: labelersTarget,
  }

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  }

  const alerts = await fetch_(
    `https://europe-west1-${
      import.meta.env.VITE_PROJECT_ID
    }.cloudfunctions.net/addAlertsToLabelingQueue`,
    options,
  )
    .then((response) => response.json())
    .then((result) => result as Alerts)

  const alertKey = Object.keys(alerts)[0]
  const alert = alerts[alertKey]

  return { alert, alertKey }
}

// Warning, must be defined out of useSequence to provide a stable reference to useQueue
// Otherwise results in an infinite re-render loop
const alertsRef = ref(database, 'alerts')

export const useAlert = () => {
  const userId = auth.currentUser?.uid

  if (!userId) {
    throw Error('Unknown user')
  }

  // undefined = no yet set
  // null = no valid data found in URL
  const [alertFromURL, setAlertFromURL] = useState<
    AlertFromURL | null | undefined
  >(undefined)

  const queue = useQueue<Alert>(userId, alertsRef)

  const [startTime, setStartTime] = useState(0)

  let currentAlert: QueueEntry<Alert> | undefined = undefined

  // Wait for URL to be parsed, otherwise queue item is used
  if (alertFromURL !== undefined) {
    // In case a URL is in use
    const urlAlert = alertFromURL
    if (urlAlert) {
      currentAlert = {
        key: urlAlert.alertKey,
        value: urlAlert.alert,
      }
    } else {
      if (!queue.loading && queue.data !== null) {
        currentAlert = queue.data.head
      }
    }
  }

  // Trigger a single url parsing on start
  useEffect(() => {
    async function runEffect() {
      setAlertFromURL(await getAlertFromURL())
    }

    runEffect()
  }, [])

  useEffect(() => {
    const storage = getStorage()
    if (!currentAlert?.value.id) {
      return
    }

    const fileName = currentAlert?.value.id + '.png'
    getDownloadURL(refStorage(storage, fileName))
      .then((url) => {
        //inserted into an <img> element
        const img = document.getElementById('alertImg') as HTMLImageElement
        if (img) {
          img.setAttribute('src', url)
        }
      })
      .catch((error: Error) => {
        onError(error)
        const img = document.getElementById('alertImg') as HTMLImageElement
        if (img) {
          img.setAttribute('alt', "Erreur : Il n'y a pas d'image associée")
        }
      })
  }, [currentAlert?.value.id])

  // Re-init start time on each alert change
  useEffect(() => {
    setStartTime(Date.now())
  }, [currentAlert?.key])

  const saveLabels = useCallback(
    async (labels: string[]) => {
      if (!currentAlert) throw Error('saveLabels called with no alert')

      const key = currentAlert.key
      const { serial, date, id, source, why } = currentAlert.value

      const alertLabels: AlertLabels = {
        ts: Date.now(), // to keep it consistent with startTime which is evaluated locally
        start_ts: startTime,
        labels: labels.sort((labelA, labelB) => {
          return labelA.localeCompare(labelB)
        }),
      }

      // Copy data to export from 'sounds' to 'labeledSounds', needed even if it's not the first labeling
      const labeledAlert = {
        serial,
        date,
        id,
        source,
        why,
      }
      await update(`labeledAlerts/${key}`, labeledAlert)

      await set(`labeledAlerts/${key}/labelers/${userId}`, alertLabels)

      await markEntryAsLabeled(alertsRef, currentAlert.key, userId, () => {
        return Promise.all([
          remove(`alerts/${currentAlert.key}`),
          set(`alertsToBeExported/${key}`, true as const),
        ])
      })

      // Trigger selection of a new alert
      if (alertFromURL) setAlertFromURL(null)
      else {
        if (queue.loading || queue.data === null) return
        queue.data.pop()
      }
    },
    [alertFromURL, currentAlert, queue, startTime, userId],
  )

  if (alertFromURL === undefined) return loadingState()

  if (currentAlert) {
    const { serial, date, id } = currentAlert.value

    return dataState<UseAlert>({
      alertKey: currentAlert.key,
      serial,
      date,
      id,
      saveLabels,
    })
  }

  if (queue.loading) return loadingState()
  return dataState<UseAlert>(null)
}
